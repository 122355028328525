.animationContainer {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  max-width: 12.5rem;
  margin: 0 auto;
}

.header {
  color: #929cb7;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.75rem;
  letter-spacing: -0.035rem;
  margin-bottom: 3.125rem;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
}

.image {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.image1 {
  animation: fadeInOut 2s infinite;
  animation-delay: 0s;
}

.image2 {
  animation: fadeInOut 2s infinite;
  animation-delay: 0.5s;
}

.image3 {
  animation: fadeInOut 2s infinite;
  animation-delay: 1s;
}

.image4 {
  animation: fadeInOut 2s infinite;
  animation-delay: 1.5s;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
